<template>
  <div class="msg-box">
    <div class="title">
      <span class="name">欢迎语</span>
      <span class="tip">{{greeting.split('').length}}/300</span>
    </div>
    <div class="content">
      <van-field
        style="padding: 0"
        v-model.trim="greeting"
        rows="4"
        type="textarea"
        :border="false"
        :disabled="textareaDisabled"
        @input="inputChange"
        :maxlength="300"
        placeholder="请输入欢迎语"
      />
    </div>
    <div class="line"></div>
    <div class="click-box">
      <span>添加更多</span>
      <input
        class="input-upload"
        id="image"
        name="image"
        type="file"
        ref="image"
        @change="imgChange"
        accept="image/*"
        multiple="multiple"
      />
      <input
        id="video"
        class="input-upload"
        name="video"
        type="file"
        ref="video"
        @change="videoChange"
        accept="video/*"
      />
      <van-button
        type="info"
        plain
        @click.stop.native="annexesClick(item)"
        v-for="(item, index) in annexesArray"
        :key="'annex_' + index"
        :disabled="
          annexesDisabled ||
          annexes.length >= annexesMax || 
          (annexesOnly && annexesOnlyType.indexOf(item) === -1)
        "
        >{{ item }}
      </van-button>
    </div>
    <div class="change-box" v-show="annexes.length > 0">
      <div
        class="list"
        v-for="(item, index) in annexes"
        :key="'a_' + index"
      >
        <span 
          class="annexes-name"
          v-if="item.attachType !==4"
        >
          {{ annexesName(item) }}
        </span>
          <span 
          class="annexes-name annexes-no"
          @click.stop="preview(item, index)"
          v-else
        >
          {{ annexesName(item) }}
        </span>
        <svg class="icon" aria-hidden="true" @click.stop="removeItem(index)">
          <use xlink:href="#iconshanchu"></use>
        </svg>
      </div>
    </div>
    <!-- 选择内容库/活动库 -->
    <content-dialog
      :contentShow.sync="contentShow"
      :cmedefaultType="cmeType"
      :estateId="estateId"
      :weChatType="weChatType"
      :annexes="annexes"
      @addContent="addContent"
      @removeItem="removeItem"
    ></content-dialog>

    <!-- 选择小程序 -->
    <wxapp-dialog
      :dialogData="dialogData"
      ref='wxappShow'
      @changeWx="changeWx"
    ></wxapp-dialog>
  </div>
</template>

<script>
import { g_annexes } from './dataConf';
import ContentDialog from './ContentDialog';
import WxappDialog from './WxappDialog';
import mine from '@/api/mine';
export default {
  data() {
    return {
      annexes: [],
      textareaDisabled: false,
      annexesDisabled: false,
      greeting: '',
      contentShow: false,
      annexesOnlyType: '',
      cmeType: 1,
      editIndex: 0,
      dialogData: {},
    };
  },
  components: {
    ContentDialog,
    WxappDialog
  },
  props: {
    //传进来的数据回填
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    //4可添加附件数量默认9，超过时，附件“添加更多”
    annexesMax: {
      type: Number,
      default: 9,
    },
    // 附件和文本框是否只能二选一
    isOnly: {
      type: Boolean,
      default: false,
    },
    annexesOnly: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    annexesArray: {
      type: Array,
      default: () => ['图片', '视频', '内容库', '活动库', '小程序']
    },
    weChatType: {
      type: Number,
      default: 0,
    },
    vedioSize: {
      type: Number,
      default: 10,
    },
    estateId: {
      type: String,
      default: ''
    }
  },
  watch: {
    modelValue() {
      this.initData();
    },
  },
  model: {
    prop: 'modelValue',
    event: 'returnModel',
  },
  methods: {
    initData() {
      this.annexes = this.modelValue.annexes || [];
      this.greeting = this.modelValue.greeting || '';
      if (this.isOnly) {
        if (this.annexes.length > 0) {
          this.textareaDisabled = true;
          this.greeting = '';
        }
        if (this.greeting.length > 0) {
          this.annexesDisabled = true;
          this.annexes = [];
        }
      }
      if (this.isDisabled) {
        this.annexesDisabled = true;
        this.textareaDisabled = true;
      }
    },
    annexesName(t) {
      return (
        g_annexes.filter((item) => item.type == t.attachType)[0].name +
        ' —  ' +
        (t.name || t.attachName || t.shareTitle)
      );
    },
    annexesClick(item) {
      switch (item) {
        case '图片':
          this.chooseImg();
          break;
        case '视频':
          this.chooseVideo();
          break;
        case '内容库':
          this.cmeType = 1;
          this.contentShow = true;
          break;
        case '活动库':
          this.cmeType = 7;
          this.contentShow = true;
          break;
        case '小程序':
          this.dialogData = {};
          this.$refs.wxappShow.changeVisiable();
          break;
      }
    },
    chooseImg() {
      let image = this.$refs.image;
      image.click();
    },
    imgChange(e) {
      //图片选择
      let proArr = [];
      if (e.target.files && e.target.files.length > 0) {
        if (e.target.files.length > this.annexesMax) {
          return this.$message.warning(`一次性最多选择${this.annexesMax}张`);
        }
        if (e.target.files.length + this.annexes.length > this.annexesMax) {
          return this.$message.warning(`超过最多上传数,${this.annexesMax}张`);
        }
        for (let file of e.target.files) {
          if (file.size > this.imgSize * 1024 * 1024) {
            document.getElementById('image').value = null;
            return this.$message.error(`图片大小不得超过${this.imgSize}M`);
          }
          let pro = this.uploadFile(file, 1, true);
          proArr.push(pro);
        }
        Promise.all(proArr)
          .then((values) => {
            for (let e of values) {
              if (e.code === '0') {
                this.annexes.push(e.data);
                if (this.isOnly && this.annexes.length > 0) {
                  this.textareaDisabled = true;
                }
                if (this.annexesOnly) {
                  //添加图片后,指定图片高亮
                  this.annexesOnlyType = '图片';
                }
                this.returnModel();
              }
              document.getElementById('image').value = null;
            }
          })
          .catch(() => {
            document.getElementById('image').value = null;
          });
      }
    },
    uploadFile(file, type, pro) {
      let form = new FormData(); // FormData 对象
      form.append('file', file); // 文件对象
      form.append('opId', localStorage.getItem('platform_opId'));
      form.append('weChatType', this.weChatType);
      if (pro) return mine.uploadContentFile(form);
      mine.uploadContentFile(form)
        .then((e) => {
          if (e.code === '0') {
            this.annexes.push(e.data);
            if (this.isOnly && this.annexes.length > 0) {
              this.textareaDisabled = true;
            }
            if (this.annexesOnly) {
              //添加图片后,指定图片高亮
              this.annexesOnlyType = type === 1 ? '图片' : '视频';
            }
            this.returnModel();
          }
          switch (type) {
            case 1:
              document.getElementById('image').value = null;
              break;
            case 2:
              document.getElementById('video').value = null;
              break;
            default:
              break;
          }
        })
        .catch(() => {
          switch (type) {
            case 1:
              document.getElementById('image').value = null;
              break;
            case 2:
              document.getElementById('video').value = null;
              break;
            default:
              break;
          }
        });
    },
    chooseVideo() {
      var video = document.getElementById('video');
      video.click();
    },
    videoChange(e) {
      //视频选择
      let file = e.target.files[0];
      if (!file) {
        return;
      }
      if (file.size > this.vedioSize * 1024 * 1024) {
        document.getElementById('video').value = null;
        return this.$message.error(`视频大小不得超过${this.vedioSize}M`);
      }
      this.uploadFile(file, 2);
    },
    returnModel() {
      this.$emit('returnModel', {
        annexes: this.annexes,
        greeting: this.greeting,
      });
    },
    removeItem(index) {
      if (this.isDisabled) {
        return;
      }
      this.annexes.splice(index, 1);
      if (this.isOnly && this.annexes.length == 0) {
        this.textareaDisabled = false;
      }

      if (this.annexesOnly && this.annexes.length == 0) {
        //全部删除后，所有可选
        this.annexesOnlyType = this.annexesArray.join('');
      }

      this.returnModel();
    },
    addContent(data) {
      this.contentShow = false;
      this.annexes = [...data];
      this.returnModel();
    },
    changeWx(data, isEdit) {
      let annex = {
        //小程序和自定义网页的标题和图片
        attachName: data.name || '',
        appId: data.appId || '', //微信小程序的appId
        appPage: data.appPage || '', //微信小程序的地址
        shareTitle: data.shareTitle || '',
        frontPic: data.frontPic || '',
        attachAbstract: data.attachAbstract || '',
        mediaId: data.mediaId,
        mediaTime: data.mediaTime,
        attachType: data.attachType,
        attachPath: data.frontPic || '',
      };
      if (!isEdit) {
        this.annexes.push(annex);
      } else {
        this.annexes[this.editIndex] = annex;
      }
      console.log( this.annexes);
      this.returnModel();
    },
    preview(data, index) {
      this.editIndex = index; //作为附件编辑标识
      this.dialogData = { ...data }; //作为附件编辑数据
      this.$refs.wxappShow.changeVisiable();
    },
    inputChange() {
      if (this.isOnly) {
        this.annexesDisabled = this.textarea.length > 0;
      }
      this.$nextTick(() => {
        this.returnModel();
      });
    },
  }
};
</script>

<style lang="less" scoped>
.msg-box{
  width: 100vm;
  padding: 14px 16px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .title{
    height: 21px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    .name{
      color: @gray6;
      font-weight: 400;
    }
    .tip{
      font-weight: 400;
      color: #B0B4BB;
    }
  }
  .content{
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
  .line {
    height: 1px;
    width: 100%;
    background: rgba(0, 0, 0, 0);
    box-shadow: inset 0px 0px 0px 1px #EBEEF5;
    margin-bottom: 16px;
  }
  .click-box{
    width: 100%;
    height: 24px;
    display: flex;
    font-size: 12px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    color: #B0B4BB;
    .input-upload {
      display: none;
    }
    button {
      padding: 3px 8px;
      height: 24px;
      line-height: 24px;
      font-size: 12px !important;
      display: inline-block;
      border-radius: 24px 24px 24px 24px;
      opacity: 1;
      border: 1px solid rgba(58, 116, 242, 0.4);
    }
  }
  .change-box{
    width: 343px;
    background: #F5F6F7;
    border-radius: 2px 2px 2px 2px;
    margin-top: 16px;
    .list{
      padding: 5px 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .annexes-name {
        display: inline-block;
        text-align: start;
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
      }
      .annexes-no{
        color: #3a74f2;
      }
    }
  }
}
</style>
