<!--
 * @Descripttion: 消息内容：选择内容库
 * @version:
 * @Author: wjq
 * @Date: 2021-07-16 16:54:58
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-05-13 15:15:00
-->
<template>
  <van-popup
    v-model="show"
    :close-on-click-overlay="false"
    :style="{ height: '400px', width: '320px', borderRadius: '4px 4px 4px 4px', background: '#F5F6F7'}"
    @open="onOpen"
  >

    <van-cell :border="false">
      <template #title>
        <span class="custom-title">{{dialogTitle}}</span>
        <van-icon name="warning-o" color="#606266" size="14" @click.stop="showTips('相关信息请联系管理员获取，或直接在小程序后台配置')"/>
      </template>
    </van-cell>

    <div class="content-box">
      <van-form @submit="onSubmit" ref="form">
        <van-field
          v-model="formData.appId"
          required
          name="APPID"
          label="APPID"
          placeholder="请输入"
          :rules="[{  required: true, message: '请输入APPID' }]"
        />
        <van-field
          style="margin-top: 12px"
          v-model="formData.appPage"
          required
          name="页面路径"
          label="页面路径"
          placeholder="请输入"
          :rules="[{ required: true, message: '请输入页面路径' }]"
        />
        <van-field
          v-model="formData.shareTitle"
          required
          name="分享标题"
          label="分享标题"
          placeholder="请输入"
          :rules="[{  required: true, message: '请输入分享标题' }]"
        />
        <van-field
          name="封面图"
          label="封面图"
          required
          style="margin-top: 12px; height: 72px; display: flex; align-items:center; justify-content: space-between;"
          :rules="[{required: true, message: '请选择封面图' }]"
        >
          <template #input>
            <van-uploader
              style="width: 55px;height: 44px;"
              :max-count="1"
              accept="image/png, image/jpeg"
              v-model="formData.frontPic"
              result-type='file'
              :after-read="afterRead" 
            />
          </template>
        </van-field>
      </van-form>
    </div>
    <div class="footer">
      <span class="calc" @click.stop="cancel">取消</span>
      <span class="submit" @click.stop="ok">确认</span>
    </div>
  </van-popup>
</template>
<script>
import { Dialog, Toast } from 'vant';
import { CLUE } from '@/api/apiConst';
import mine from '@/api/mine';
export default {
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      CLUE,
      show: false,
      formData: {
        appId: '',
        appPage: '',
        //小程序图片
        mediaId: '',
        shareTitle: '',
        frontPic: [],
        mediaTime: '',
      },
      dialogTitle: '添加小程序'
    };
  },
  methods: {
    changeVisiable() {
      this.show = true;
      this.formData.appId = '';
      this.formData.appPage = '';
      this.formData.mediaId = '';
      this.formData.shareTitle = ''; 
      this.formData.frontPic = [];
      this.formData.mediaTime = [];
    },
    showTips(message) {
      Dialog.alert({
        title: '说明',
        message: message,
        confirmButtonText: '我知道了',
        confirmButtonColor: '#3A74F2',
        className: 'follow-btn',
        closeOnClickOverlay: true,
      }).then(() => {
        // on close
      });
    },
    onSubmit() {
      let wxApp = {
        attachType: 4,
      };
      wxApp.name = this.formData.shareTitle;
      wxApp.attachPath = this.formData.attachPath;
      wxApp.appId = this.formData.appId;
      wxApp.appPage = this.formData.appPage;
      wxApp.mediaId = this.formData.mediaId;
      wxApp.mediaTime = this.formData.mediaTime;
      wxApp.shareTitle = this.formData.shareTitle;
      wxApp.frontPic = this.formData.frontPic[0].url;
      this.$emit('changeWx', wxApp, !!this.dialogData.appId);
      this.cancel();
    },
    async onOpen() {
      if (this.dialogData.appId) {
        this.dialogTitle = '编辑小程序';
        Object.keys(this.formData).forEach(item => {
          if (item === 'frontPic') {
            this.formData[item] = [{url: this.dialogData[item]}];
          } else {
            this.formData[item] = this.dialogData[item];
          }
        });
      } else {
        this.dialogTitle = '添加小程序';
        this.formData = {
          appId: '',
          appPage: '',
          mediaId: '',
          shareTitle: '',
          frontPic: [],
          mediaTime: '',
        };
      }
    },
    cancel() {
      this.show = false;
    },
    afterRead(e) {
      //图片选择
      let img = e.file;
      if (!img) {
        return;
      }
      if (img.size > 2 * 1024 * 1024) {
        return Toast('图片不能选择2M以上');
      }
      this.uploadFile(e.file);
    },
    uploadFile(file) {
      let form = new FormData(); // FormData 对象
      form.append('file', file); // 文件对象
      form.append('opId', localStorage.getItem('platform_opId'));
        mine.uploadContentFile(form)
        .then((e) => {
          console.log(e);
          if (e.code === '0') {
            //换取图片的media信息
            this.formData.mediaId = e.data.mediaId;
            this.formData.frontPic = [{url: e.data.attachPath}];
            this.formData.mediaTime = e.data.mediaTime;
          } else {
            this.formData.frontPic = [];
            Toast(e.msg);
          }
        })
        .catch(() => {
          this.formData.frontPic = [];
          Toast('小程序上传封面图失败');
        }); 
    },
    ok() {
      this.$refs['form'].submit();
    },
  },
};
</script>
<style lang="less" scoped>
.title{
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.content-box{
  widows: 100%;
  height: 267px;
  overflow: auto;
  /deep/ .van-uploader__wrapper{
    width: 55px !important;
    height: 44px !important;
    .van-uploader__upload{
      width: 55px !important;
      height: 44px !important;
    }
    .van-uploader__preview{
        width: 55px !important;
        height: 44px !important;
      .van-image{
        width: 55px !important;
        height: 44px !important;
      }
    }
  }
}
.footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  .calc{
    width: 111px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #F0F4FE;
    border-radius: 6px 6px 6px 6px;
    font-size: 17px;
    font-weight: 600;
    color: #3A74F2;
  }
  .submit{
    width: 168px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #3A74F2;
    border-radius: 6px 6px 6px 6px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 17px;
  }
}
</style>
