<template>
  <van-dialog v-model="show"
              show-cancel-button
              class="addPersonDialog"
  >
    <div class="content">
      <div class="title">
        <h6>{{tkId ? '绑定拓客人':"添加拓客人"}} <van-icon name="warning-o" color="#606266" size="14" @click="showTips('仅能绑定该项目下归属于你的微信潜客哟')"/></h6>
        <van-search v-model="search" placeholder="昵称或手机号" @search="onRefresh" @clear="clearSearch" />
      </div>
      <div class="tips">共计{{totalRecords}}个客户</div>
      <div class="addPerson-list" >
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-if="list.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList" :offset="100">
            <div class="addPerson-item" v-for="(item, index) of list" :key="index" @click="chose(item)">  
              <div class="head">
                <img :src="item.avatar" alt="">
              </div>
              <div class="person-info">
                <strong>{{item.nickName}}
                  <svg v-if="item.hasFocusQw" class="icon" aria-hidden="true" style="width:16px;height:16px;">
                    <use xlink:href="#iconqiyeweixin"></use>
                  </svg>
                </strong>
                <span>{{item.phone?item.phone:'-'}}</span>
              </div>
              <van-icon name="checked" color="#3a74f2" size="24" v-if="item.clueId === choseItem.clueId"/>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    <div class="wxb-btn">
      <div class="cancel-btn" @click="cancelBtn">取消</div>
      <div class="confirm-btn" @click="confirmBtn">确认</div>
    </div>
  </van-dialog>
</template>

<script>
import { Dialog } from 'vant';
import mine from '@/api/mine';
export default {
name: 'wxbCueToCusDialog',
  components: {
  },
  props: {
    addPersonDialogShow: {
      type: Boolean,
      default: false
    },
    tkId: {
      type: String,
      default: ''
    },
    clueId: {
      type: String,
      default: ''
    }
  },
  watch: {
    addPersonDialogShow(newVal) {
      this.show = newVal;
      if (newVal) {
         this.pageNation.pageNo = 1;
        this.getList();
      }
    },
    clueId(newVal) {
      if (newVal) {
        this.choseItem.clueId = newVal;
      }
    }
  },
  data() {
    return {
      show: false,
      list: [],
      search: '',
      pageNation: {
        pageNo: 1,
        pageSize: 10
      },
      totalRecords: '0',
      totalPages: 0,
      loading: false,
      finished: false,
      refreshing: false,
      choseItem: {},
    };
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let { code, data, msg } = await mine.getClueListBySaler({keyword: this.search});
        this.loading = false;
        if (code !== '0') {
          this.finished = true;
          console.error('接口报错', msg);
          return;
        }
        if (this.pageNation.pageNo >= data.totalPages || data.totalPages === 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        if (this.pageNation.pageNo === 1) {
          this.list = data.data;
        } else {
          this.list = this.list.concat(data.data);
        }
        this.totalPages = data.totalPages;
        this.totalRecords = data.totalRecords;
        this.pageNation.pageNo++;
      } catch (err) {
        console.error('client overdue err', err);
        this.loading = false;
      }
    },
    chose(item) {
      this.choseItem = item;
    },
    async confirmBtn() {
      if (this.tkId) { //直接绑定
        let data = {
          tkId: this.tkId,
          clueId: this.choseItem.clueId,
          clueName: this.choseItem.nickName,
          clueAvatar: this.choseItem.avatar,
          cluePhone: this.choseItem.phone,
        };
        let res = await mine.bindTalkCode(data);
        console.log('res', res);
        this.$toast.fail('绑定成功');
        this.$emit('addPersonDialogClose', {flag: false});
        this.$emit('refreshPage');
        return ;
      }
      // 添加绑定人
      this.$emit('addPersonDialogClose', {item: this.choseItem, falg: false});
    },
    clearSearch() {
      this.search = '';
      this.pageNation.pageNo = 1;
      this.getList();
    },
    onRefresh() {
      this.pageNation.pageNo = 1;
      this.getList();
    },
    cancelBtn() {
      this.$emit('addPersonDialogClose', {flag: false});
    },
    showTips(message) {
      Dialog.alert({
        title: '说明',
        message: message,
        confirmButtonText: '我知道了',
        confirmButtonColor: '#3A74F2',
        className: 'follow-btn',
        closeOnClickOverlay: true,
      }).then(() => {
        // on close
      });
    },
  }
};
</script>

<style scoped lang="less">
.addPersonDialog{
  .tips{
    font-size: 12px;
    font-weight: 400;
    color: #909399;
  }
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 0 14px 0;
    h6{
      margin: 0;
      font-size: 17px;
      font-weight: 600;
      color: #303133;
      white-space: nowrap;
    }
    .van-search{
      padding: 0px;
      .van-search__content{
        padding-left: 0;
        .van-cell{
          padding: 6px;
          background: #F5F6F7;
          border-radius: 4px;
        }
      }
    }
  }
  .addPerson-list{
    margin: 10px 0;
    max-height: 50vh;
    overflow-y: auto;
    .addPerson-item{
      display: flex;
      align-items: center;
      background: #F5F6F7;
      border-radius: 4px;
      padding: 12px;
      margin-top: 10px;
      .head{
        width: 48px;
        height: 48px;
        border-radius: 8px;
        border: 1px solid #EBEEF5;
        overflow: hidden;
        margin-right: 12px;
        img{
          width: 48px;
        }
      }
      .person-info{
        display: flex;
        flex-direction: column;
        flex: 1;
        strong{
          margin-top: 3px;
          font-size: 15px;
          font-weight: 500;
          color: #303133;
          line-height: 18px;
        }
        span{
          font-size: 12px;
          font-weight: 400;
          color: #606266;
          line-height: 14px;
          margin-top: 4px;
        }  
      }
      .time{
        font-size: 12px;
        font-weight: 400;
        color: #909399;
        line-height: 14px;
      }
    }
  }
  
}

</style>
<style lang="less">
  .addPersonDialog .van-dialog__content{
    padding: 0 0 16px 0;
    .content{
      padding: 4px 16px 24px 16px;

    }
  }
  .addPersonDialog .van-dialog__footer{
    display: none;
  }
</style>
