/*
 * @Descripttion: -
 * @version: -
 * @Author: wjq
 * @Date: 2021-07-20 10:27:12
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-05-09 11:20:56
 */
export const g_annexes = [
  {
    type: 1,
    name: '图片',
  },
  {
    type: 2,
    name: '视频',
  },
  {
    type: 3,
    name: '网页',
  },
  {
    type: 4,
    name: '小程序',
  },
  {
    type: 5,
    name: '文件',
  },
  {
    type: 6,
    name: '网页',
  },
];

export const CMEType = { //1文章;2表单;3海报;4H5传单;5视频;6VR;7活动
  Article: 1,
  Form: 2,
  Poster: 3,
  Video: 5,
  H5Leaflets: 4,
  Activity: 7
};
