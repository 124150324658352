import request from '../../util/request';
import { CRM, BASIC, CLUE } from '../apiConst';

export default {
  //保存名片配置
  saveOwnerCard (data) {
    return request.post({ url: `${CRM}/crm/mobile/v1/owner-card/save`, data });
  },
  //上传图片
  uploadImg (formData) {
    return request.postFile({ url: `${BASIC}/file/v1/image`, formData });
  },
  //获取海报
  getPosters () {
    return request.post({ url: `${CRM}/crm/mobile/v1/image/share` });
  },
  //解绑
  unbindUser (id, params) {
    return request.delete({ url: `${BASIC}/wecom/v1/unbindUser/${id}?opId=${params.opId}` });
  },
  //退出登录态
  loginOut () {
    return request.get({ url: '/auth/logout' });
  },
  //检查当前销售信息
  getSaleInfo (params) {
    return request.get({ url: `${BASIC}/wecom/v1/getSaleInfo`, params });
  },

  //获取雷达设置
  getRadarSet (params) {
    return request.get({ url: `${CLUE}/global/setting/v1/radar-setting`, params });
  },

  //设置雷达
  setRadar (data) {
    return request.post({ url: `${CLUE}/global/setting/v1/radar-setting`, data });
  },

  //修改密码
  changPwd (data) {
    return request.post({ url: `${BASIC}/wecom/v1/change-password`, data });
  },
  //获取内容上传详情 包含 图片，视频，内容中的海报，小程序的图片
  uploadContentFile(formData) {
    return request.postFile({ url: `${CLUE}/common/v1/upload`, formData });
  },
  getCmeList(data) {
    data['opId'] = localStorage.getItem('platform_opId');
    return request.post({url: `${CLUE}/mar/cme/v1/cme-page`, data, headers: {appPermit: 'potential'}});
  },
  //分页查询我的拓客码
  getTalkCodeList(data) {
    data['addUserId'] = JSON.parse(localStorage.getItem('platform_user')).userId;
    data['estateId'] = localStorage.getItem('platform_estateId');
    return request.post({url: `${CLUE}/tk-code/app-page`, data});
  },
  //新增修改
  addAndEditTalkCode(data) {
    return request.post({url: `${CLUE}/tk-code`, data, removeOneEmpty: true});
  },
  //拓客人绑定
  bindTalkCode(data) {
    return request.post({url: `${CLUE}/tk-code/bind-clue`, data});
  },
  //根据id查询拓客信息
  getTalkCodeInfo(id) {
    return request.get({ url: `${CLUE}/tk-code/${id}` });
  },
  //查询拓客码(拓客明细)
  getTalkCodeDetail (data) {
    return request.post({ url: `${CLUE}/tk-code/look-detail`, data});
  },
  //查询项目潜客列表
  getClueListBySaler(data) {
    data['opId'] = localStorage.getItem('platform_opId');
    data['estateId'] = localStorage.getItem('platform_estateId');
    data['clueType'] = 10;
    return request.post({url: `${CLUE}/common/v1/base-clue-info`, data});
  },
  //获取标签树
  getTagTree (params) {
    return request.get({ url: `${BASIC}/tag/v1/tag-tree`, params });
  },
  //查询拓客码
  getCodeInfoList(params) {
    return request.get({ url: `${CLUE}/tk-code/clue-code`, params });
  }
};
