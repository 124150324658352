<template>
  <van-action-sheet class="van-action-tag" v-model="show" @click-overlay="cancel">
    <div class="wxb-pop-btn sticky-class">
      <div class="pop-cancel-btn" @click="cancel">取消</div>
      <div class="pop-title">{{title}}</div>
      <div class="pop-confirm-btn" @click="confirm">确认</div>
    </div>
    <div class="content-box">
      <van-form @submit="onSubmit" ref="formData">
        <van-field
          v-if="!sidebar"
          input-align="right"
          size="large"
          readonly
          clickable
          name="picker"
          :value="formData.talkUser.clueName"
          placeholder="请选择"
          @click="addPersonDialogShow = true"
        >
          <div slot="label" class="flex" @click="showTip1()">
            <span class="custom-title">拓客人</span>
            <van-icon name="warning-o" color="#606266" size="14" @click.stop.native="showTips('仅能绑定该项目下归属于你的微信潜客哟')"/>
          </div>
        </van-field>
        <van-cell-group style="margin-top: 16px" :border="false">
          <van-field
            v-model="formData.qrName"
            name="二维码名称"
            label="二维码名称"
            maxlength="30"
            placeholder="请输入"
            input-align="right"
            size="large"
            required
            :rules="[{ required: true }]"
          />
          <van-field name="switch" label-width="260" label="客户添加时无需经过确认自动成为好友" input-align="right">
            <template #input>
              <van-switch v-model="formData.skipVerify" size="24" />
            </template>
          </van-field>
        </van-cell-group>
        <van-cell-group style="margin-top: 16px" :border="false">
          <van-field
              readonly
              clickable
              is-link
              input-align="right"
              placeholder="请选择"
              :value="sourceValue.knowWayDetail"
              name="认知途径"
              label="认知途径"
              class="van-cell-last"
              @click="sourceShow = true"
          >
            <div slot="label" class="flex" @click="showTip1()">
              <span class="custom-title">认知途径</span>
              <van-icon name="warning-o" color="#606266" size="14" @click.stop.native="showTips('当客户扫码添加时，自动为其标识对应的渠道来源')"/>
            </div>
          </van-field>
          <van-cell
              class="cell-line"
              size="large"
              :value="formData.tags"
              @click="tagClick()"
          >
            <template slot="title">
              <span class="form-title">标签</span>
              <van-icon name="warning-o" color="#606266" size="14" @click.stop.native="showTips('当客户扫码添加时，自动为其打上所选择的标签')"/>
            </template>
            <template slot>
              <span class="custom-place" v-if="resultSelectTagList&&resultSelectTagList.length===0">请选择</span>
              <template v-else>
                <span
                    class="select-tag"
                    v-for="item of resultSelectTagList"
                    :key="item.tagId"
                >{{ item.tagName }}</span
                >
                <span class="select-tag" v-if="selectTagList.length > 3">...</span>
              </template>
            </template>
          </van-cell>
        </van-cell-group>
      </van-form>
      <msgContent v-model="formData.content" :estateId="estateId"></msgContent>
    </div>
    <!-- 绑定拓客人 -->
    <addPersonDialog :clueId="formData.talkUser.clueId" :addPersonDialogShow="addPersonDialogShow" @addPersonDialogClose="addPersonDialogClose"></addPersonDialog>
    <!--    来源  -->
    <van-popup v-model="sourceShow" round position="bottom">
      <template v-if="sourceOptions.length > 0">
        <van-cascader
            class="van-cascader-source"
            active-color="#3A74F2"
            title="认知途径"
            :closeable="false"
            v-model="sourceValue.knowWay"
            :options="sourceOptions"
            :field-names="sourceFieldNames"
            @finish="onFinishSource"
        />
      </template>
      <div class="no-tag" v-else>
        <wxbNoData></wxbNoData>
      </div>
    </van-popup>
    <!--标签选择-->
    <actionSheetTag
        :actionTagShow="actionTagShow"
        :tagList="tagList"
        :selectTagList="selectTagList"
        @actionTagClose="actionTagClose"
    >
    </actionSheetTag>
  </van-action-sheet>
</template>

<script>
import field from '../../../api/field';
import addPersonDialog from './addPersonDialog.vue';
import msgContent from '@/components/msgContent';
import { Dialog } from 'vant';
import mine from '@/api/mine';
import { recursionGet } from '@/util/util';
import { Toast } from 'vant';
export default {
  name: 'editTalkCode',
  components: {
    addPersonDialog,
    msgContent
  },
  props: {
    sidebar: {
      type: Boolean,
      default: false
    },
    clueInfo: {
      type: Object,
      default: () => {
        return {};
      }
    },
    estateId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: false,
      title: '新增托客人',
      taskId: '',
      formData: {
        talkUser: {
          clueName: '',
          clueId: '',
          cluePhone: '',
          clueAvatar: ''
        },
        qrName: '',
        skipVerify: true,
        content: {},
        addUserId: '',
        addUserName: ''
      },
      addPersonDialogShow: false,
      sourceShow: false, //来源弹框
      sourceOptions: [],
      sourceValue: { knowWay: '', knowWayDetail: '' },
      sourceFieldNames: {
        text: 'name',
        value: 'id',
        children: 'children',
      },
      actionTagShow: false, //标签
      tagList: [], // 标签列表
      selectTagList: [], // 已选择的标签列表
      talkId: ''
    };
  },
  computed: {
    //标签展示
    resultSelectTagList() {
      return this.selectTagList.slice(0, 3); // selectTagList 原始数据
    }
  },
  methods: {
    // 清除数据
    resetData() {
      this.formData = {
        talkUser: {
          clueName: '',
          clueId: '',
          cluePhone: '',
          clueAvatar: ''
        },
        qrName: '',
        skipVerify: true,
        content: {},
        addUserId: '',
        addUserName: ''
      };
      this.sourceValue = { knowWay: '', knowWayDetail: '' };
      this.selectTagList = [];
    },
    async init(Id) {
      this.resetData();
      await this.playTag();
      await this.getChannelTree();
      this.talkId = Id || '';
      this.show = true;
      if (this.talkId) {
        this.title = '编辑拓客码';
        this.getInfo(Id);
      } else {
        this.title = '新增拓客码';
      }
    },
    //获取标签树platform_estateId
    async playTag() {
      let { code, data } = await mine.getTagTree({ opId: localStorage.getItem('platform_opId'), estateId: this.estateId, tagTypes: [1, 2].toString() });
      if (code === '0') {
        this.tagList = data;
      }
    },
    //回填信息
    async getInfo(id) {
      this.taskId = id || '';
      let {code, data} = await mine.getTalkCodeInfo(id);
      if (code === '0') {
        // 拓客人
        this.formData.talkUser = {
          clueName: data.clueName,
          clueId: data.clueId,
          cluePhone: data.cluePhone,
          clueAvatar: data.clueAvatar,
        },
        this.formData.qrName = data.qrName;
        this.formData.skipVerify = data.skipVerify || false;
        this.formData.addUserId = data.addUserId;
        this.formData.addUserName = data.addUserName;
        // 来源
        this.sourceValue.knowWay = data.knowWay;
        this.sourceValue.knowWayDetail = data.knowWayDetail;
        // 标签列表
        recursionGet(data.tagIds, this.tagList, this.selectTagList, 'childrenTags', 'tagId');
        // 内容
        this.formData.content = {
          greeting: data.greeting,
          annexes: data.attachList
        };
      }
    },
    addPersonDialogClose(flag) {
      if (flag.item) {
        this.formData.talkUser = {
        clueName: flag.item.nickName,
        clueId: flag.item.clueId,
        cluePhone: flag.item.phone,
        clueAvatar: flag.item.avatar,
      };
      }
      this.addPersonDialogShow = false;
    },
    // 标签选择
    actionTagClose({ type, data }) {
      this.actionTagShow = type;
      if (data) {
        this.selectTagList = data;
      }
    },
    //打开标签弹框
    tagClick() {
      this.actionTagShow = true;
    },
    // 获取来源
    async getChannelTree() {
      let params = {
        estateId: this.estateId,
      };
      let { code, data, msg } = await field.channelTree(params);
      if (code !== '0') {
        console.error('接口报错', msg);
        this.$toast.fail('获取来源失败');
        
      } else {
        if (!data && data.length === 0) {
          this.sourceOptions = [];
        }
        // 禁用 不展示
        let resData = data.filter((item) => {
          return item.status === 1;
        });
        //  将扁平数组根据type(1线上 2线下)转化为tree树形结构
        let arr = [
          {
            type: 1,
            name: '线上',
            children: [],
            id: '线上',
          },
          {
            type: 2,
            name: '线下',
            children: [],
            id: '线下',
          },
        ];
        for (const item of resData) {
          if (item.type === 1) {
            arr[0].children.push(item);
          } else {
            arr[1].children.push(item);
          }
        }
        this.sourceOptions = arr;
      }
    },
    // 选择来源:全部选项选择完毕后，会触发 finish 事件
    onFinishSource({ value, selectedOptions }) {
      this.sourceShow = false;
      this.sourceValue.knowWayDetail = selectedOptions
          .map((option) => option.name)
          .join('-');
      this.sourceValue.knowWay = value;
    },
    async onSubmit() {
      let tagIds = this.selectTagList.map(item => {
        return item.tagId;
      });
      let data = {
        opId: localStorage.getItem('platform_opId'),
        estateId: this.estateId,
        tkId: this.taskId,
        qrName: this.formData.qrName,
        clueId: this.talkId ? this.formData.talkUser.clueId : this.clueInfo.clueId,
        clueName: this.talkId ? this.formData.talkUser.clueName : this.clueInfo.nickName,
        cluePhone: this.talkId ? this.formData.talkUser.cluePhone : this.clueInfo.phone,
        clueAvatar: this.talkId ? this.formData.talkUser.clueAvatar : this.clueInfo.avatar,
        addUserId: this.formData.addUserId ? this.formData.addUserId : JSON.parse(localStorage.getItem('platform_user')).userId,
        addUserName: this.formData.addUserName ? this.formData.addUserName : JSON.parse(localStorage.getItem('platform_user')).token.userName,
        skipVerify: this.formData.skipVerify,
        knowWay: this.sourceValue.knowWay,
        knowWayDetail: this.sourceValue.knowWayDetail,
        greeting: this.formData.content.greeting,
        attachList: this.formData.content.annexes,
        tagIds: tagIds,
      };
      let { code } = await mine.addAndEditTalkCode(data);
      if (code === '0') {
        Toast('操作成功');
        this.$emit('refreshPage');
        this.cancel();
      }
    },
    confirm() {
      this.$refs['formData'].submit();
    },
    cancel() {
      this.show = false;
    },
    showTips(message) {
      Dialog.alert({
        title: '说明',
        message: message,
        confirmButtonText: '我知道了',
        confirmButtonColor: '#3A74F2',
        className: 'follow-btn',
        closeOnClickOverlay: true,
      }).then(() => {
        // on close
      });
    },
  }
};
</script>

<style scoped lang="less">
.van-action-tag{
  .van-action-sheet__content{
    display: flex;
    flex-direction: column;
  }
}
.content-box{
  background: #F5F6F7;
  overflow-y: auto;
}
.select-tag {
  display: inline-block;
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid rgba(58, 116, 242, 0.4);
  margin-right: 4px;
  color: @blue;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  max-width: 62px;
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="less">
.follow-btn {
  .van-dialog__confirm {
    background: #f0f4fe;

    &:active {
      background: #f0f4fe;
    }
  }
}
</style>
