<template>
  <div class="text-content">
    <p class="tip">共计{{total}}条数据</p>
    <div class="text-list">
      <van-pull-refresh v-model="loading" @refresh="onRefresh">
        <wxbNoData v-show="noStore"></wxbNoData>
        <van-list v-model="loading" :finished="finished" :finished-text="list.length > 5 ? '没有更多了' : ''" @load="getList" offset="100">
          <div class="text-list-item" v-for="(item, index) of list" :key="index" @click.stop="changeClick(item, index)">
            <div class="left">
              <img :src="item.imgUrl" alt="">
            </div>
            <div class="right">
              <h6>{{item.title}}</h6>
              <p>{{item.guideAbstract || ""}}</p>
            </div>
            <div class="icon" v-show="item.check">
              <van-icon name="checked" :size="20" :color="'#3A74F2'"/>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>

  import mine from '@/api/mine';
  import { Toast } from 'vant';
  export default {
    name: 'imgAndText',
    props: {
      cmeType: {
        type: Number,
        default: () => null,
      },
      keyword: {
        type: String,
        default: () => null
      },
      changData: {
        type: Array,
        default: ()=> {
          return [];
        }
      },
      estateId: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        noStore: false,
        finished: true,
        loading: false,
        list: [],
        totalPages: 0,
      };
    },
    methods: {
      async getList(pageNo) {
        if (pageNo == 1) {
          this.pageNo = 1;
        }
        let query = {
          cmeType: this.cmeType,
          keyword: this.keyword,
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          estateId: this.estateId
        };
        let { data } = await mine.getCmeList(query);
        this.loading = false;
        if (this.pageNo === data.totalPages || data.totalPages===0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        if (data.data.length > 0) {
          let changIds = [];
          this.changData.forEach(item => {
            if (item.contentId) {
              changIds.push(item.contentId);
            }
          });
          data.data.forEach(item => {
            if (changIds.includes(item.cmeId)) {
              item.check = true;
            } else {
              item.check = false;
            }
          });
        }
        this.list = this.pageNo == 1 ? data.data || [] : this.list.concat(data.data || []);
        this.total = parseInt(data.totalRecords || '0');

        this.totalPages = data.totalPages;
        if (data.totalRecords == 0) {
          this.noStore = true;
        } else {
          this.noStore = false;
        }
        this.pageNo++;
      },
      changeCheck() {
        if (this.list.length > 0) {
          let changIds = [];
          this.changData.forEach(item => {
            if (item.contentId) {
              changIds.push(item.contentId);
            }
          });
          this.list.forEach(item => {
            if (changIds.includes(item.cmeId)) {
              item.check = true;
            } else {
              item.check = false;
            }
          });
        }
      },
      onRefresh() {
        this.getList(1);
      },
      changeClick(item) {
        if (this.changData.length >= 9 && !item.check) {
          Toast('最多选择9条');
          return;
        }
        item.check = !item.check;
        this.$nextTick(()=> {
          this.$emit('addContent', item);
        });
      }
    },
    mounted() {
      this.getList();
    }
  };
</script>

<style lang="less" scoped>
  .text-content{
    width: 100%;
    height: 100%;
    p,h6{
      margin: 0;
    }
    .tip{
      font-size: 12px;
      font-weight: 400;
      color: @gray6;
      height: 44px;
      line-height: 44px;
      padding: 0 16px;
      box-sizing: border-box;
    }
    .text-list{
      width: 100%;
      .text-list-item{
        width: 100%;
        height: 72px;
        padding: 12px;
        display: flex;
        align-items: center;
        background: #F5F6F7;
        margin-top: 10px;
        border-radius: 4px 4px 4px 4px;
        box-sizing: border-box;
        &:first-child{
          margin-top: 0px;
        }
        .left{
          width: 48px;
          height: 48px;
          overflow: hidden;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #EBEEF5;
          img{
            width: 48px;
            height: 48px;
          }
        }
        .right{
          width: calc(100% - 90px);
          margin-left: 12px;
          overflow: hidden;
          h6{
            width: 100%;
            font-size: 15px;
            font-weight: 500;
            color: @gray3;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 20px;
          }
          p{
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 4px;
            font-size: 12px;
            font-weight: 400;
            color: @gray9;
            line-height: 17px;
          }
        }
      }
    }
  }
</style>
