<template>
  <van-action-sheet class="van-action-talkCodeDetail" v-model="show" @click-overlay="cancel">
      <div class="wxb-pop-btn">
        <div class="pop-cancel-btn" @click="cancel">取消</div>
        <div class="pop-title">拓客明细</div>
        <div class="pop-cancel-btn"></div>
      </div>
      <div class="content">
        <div class="tips">TA共计邀请了 {{totalRecords}} 个客户</div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-if="list.length > 0" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList" :offset="100">
            <div class="person-item" v-for="(item, index) of list" :key="index">
              <div class="head">
                <img :src="item.avatar" alt="">
              </div>
              <div class="person-info">
                <strong>
                  {{item.nickName || "-"}}
                  <svg v-if="item.hasFocusQw" class="icon" aria-hidden="true" style="width:16px;height:16px;">
                    <use xlink:href="#iconqiyeweixin"></use>
                  </svg>
                </strong>
                <span>{{item.phone || "-"}}</span>
              </div>
              <div class="time">{{item.createTime}} 添加</div>
            </div>
          </van-list>
          <wxb-no-data v-else />
        </van-pull-refresh>
        
      </div>
    </van-action-sheet>
</template>
<script>
import mine from '@/api/mine';

export default {
  name: 'talkCodeDetail',
  props: {
    talkCodeDetailShow: {
      type: Boolean,
      default: false
    },
    tkId: {
      type: String,
      default: ''
    }
  },
  watch: {
    talkCodeDetailShow(newVal) {
      this.show = newVal;
      if (newVal) {
        this.pageNation.pageNo = 1;
        this.getList();
      }
    },
  },
  data() {
    return {
      show: false,
      pageNation: {
        pageNo: 1,
        pageSize: 10
      },
      totalRecords: '0',
      totalPages: 0,
      loading: false,
      finished: false,
      refreshing: false,
      list: [],
    };
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        let { code, data, msg } = await mine.getTalkCodeDetail({id: this.tkId, pageSize: this.pageNation.pageSize, pageNo: this.pageNation.pageNo});
        this.loading = false;
        if (code !== '0') {
          this.finished = true;
          console.error('接口报错', msg);
          return;
        }
        if (this.pageNation.pageNo >= data.totalPages || data.totalPages === 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        if (this.pageNation.pageNo === 1) {
          this.list = data.data;
        } else {
          this.list = this.list.concat(data.data);
        }
        this.totalPages = data.totalPages;
        this.totalRecords = data.totalRecords;
        this.pageNation.pageNo++;
      } catch (err) {
        console.error('client overdue err', err);
        this.loading = false;
      }
      
    },
    cancel() {
      this.$emit('talkCodeDetailClose');
    },
    onRefresh() {
      this.pageNation.pageNo = 1;
      this.getList();
    },
  }
};
</script>
<style lang="less" scoped>
  .van-action-talkCodeDetail{
    .content{
      background: #F5F6F7;
      padding-bottom: 24px;
      .tips{
        padding: 12px;
        font-size: 12px;
        font-weight: 400;
        color: #606266;
        line-height: 18px;
      }
      .person-item{
        display: flex;
        align-items: top;
        background: #FFFFFF;
        border-radius: 4px;
        opacity: 1;
        margin: 0 12px;
        padding: 12px;
        .head{
          width: 48px;
          height: 48px;
          border-radius: 8px;
          border: 1px solid #EBEEF5;
          overflow: hidden;
          margin-right: 12px;
          img{
            width: 48px;
          }
        }
        .person-info{
          display: flex;
          flex-direction: column;
          flex: 1;
          strong{
            margin-top: 3px;
            font-size: 15px;
            font-weight: 500;
            color: #303133;
            line-height: 18px;
          }
          span{
            font-size: 12px;
            font-weight: 400;
            color: #606266;
            line-height: 14px;
            margin-top: 4px;
          }  
        }
        .time{
          font-size: 12px;
          font-weight: 400;
          color: #909399;
          line-height: 14px;
        }
      }
    }
  }
</style>
