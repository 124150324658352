<!--
 * @Descripttion: 消息内容：选择内容库
 * @version:
 * @Author: wjq
 * @Date: 2021-07-16 16:54:58
 * @LastEditors: wh
 * @LastEditTime: 2022-05-12 16:01:15
-->
<template>
  <van-popup
    v-model="contentShow" 
    :close-on-click-overlay="false"
    :style="{ height: '460px', width: '320px', borderRadius: '4px 4px 4px 4px', padding: '24px 16px' }"
    @open="onOpen"
  >
    <div class="title">
      <span>添加内容</span>
      <van-search v-model="keyword" placeholder="输入内容标题" @search="search" />
    </div>
    <div class="content-box">
      <van-tabs ref="wxbTabs" v-model="cmeType" sticky swipeable v-if="cmeType !== 7">
        <van-tab title="文章" :name="CMEType.Article">
          <ImgAndText ref="ImgAndText" 
            v-if="cmeType === CMEType.Article"
            :cmeType="cmeType"
            :keyword="keyword"
            :changData="changeData"
            :estateId="estateId"
            @addContent="addContent"
          ></ImgAndText>
        </van-tab>
        <van-tab title="表单" :name="CMEType.Form" >
          <ImgAndText 
            ref="ImgAndText"
            v-if="cmeType === CMEType.Form"
            :cmeType="cmeType"
            :keyword="keyword"
            :estateId="estateId"
            :changData="changeData" 
            @addContent="addContent">
          </ImgAndText>
        </van-tab>
        <van-tab title="海报" :name="CMEType.Poster">
          <ImgAndText 
            ref="Poster"
            v-if="cmeType === CMEType.Poster"
            :cmeType="cmeType"
            :keyword="keyword"
            :estateId="estateId"
            :changData="changeData"
            @addContent="addContent">
          </ImgAndText>
        </van-tab>
        <van-tab title="视频" :name="CMEType.Video">
          <ImgAndText 
            ref="ImgAndText" 
            v-if="cmeType === CMEType.Video" 
            :cmeType="cmeType" 
            :keyword="keyword"
            :estateId="estateId"
            :changData="changeData" 
            @addContent="addContent">
          </ImgAndText>
        </van-tab>
        <van-tab title="传单" :name="CMEType.H5Leaflets">
          <ImgAndText 
            ref="ImgAndText" 
            v-if="cmeType === CMEType.H5Leaflets"
            :estateId="estateId"
            :cmeType="cmeType"
            :keyword="keyword"
            :changData="changeData"
            @addContent="addContent">
          </ImgAndText>
        </van-tab>
      </van-tabs>
      <ImgAndText 
        v-else
        ref="ImgAndText" 
        :cmeType="cmeType" 
        :keyword="keyword"
        :estateId="estateId"
        :changData="changeData"  
        @addContent="addContent">
      </ImgAndText>
    </div>
    <div class="footer">
      <span class="calc" @click.stop="cancel">取消</span>
      <span class="submit" @click.stop="submit">确认({{changeData.length}})</span>
    </div>
  </van-popup>
</template>
<script>
import ImgAndText from './components/imgAndText';
import { CMEType } from './dataConf';
import mine from '@/api/mine';
import { Toast } from 'vant';

export default {
  components: {
    ImgAndText,
  },
  props: {
    contentShow: {
      type: Boolean,
      default: false,
    },
    cmedefaultType: {
      type: Number,
      default: 1,
    },
    noPoster: {
      type: Boolean,
      default: false,
    },
    weChatType: {
      type: Number,
      default: 0,
    },
    outWxAppId: {
      type: String,
      default: '',
    },
    annexes: {
      type: Array,
      default: () => [],
    },
    estateId: {
      type: String,
      default: ''
    }
  },
  watch: {
    annexes: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.changeData = [...newVal];
      }
    }
  },
  data() {
    return {
      CMEType: CMEType,
      keyword: '',
      imgUrl: '',
      cmeType: 1,
      changeData: []
    };
  },
  methods: {
    async onOpen() {
      this.cmeType = this.cmedefaultType;
      if (this.$refs['ImgAndText']) {
        this.$refs['ImgAndText'].changeCheck();
      }
      console.log(this.cmeType);
    },
    search() {
      this.$refs['ImgAndText'].getList(1);
    },

    addContent(value) {
      if (!value.check) {
        this.changeData.forEach((item, index) => {
          if (item.contentId === value.cmeId) {
            this.changeData.splice(index, 1);
          }
        });
      } else {
        value.attachType = value.cmeType == 3 ? 1 : 3;
        if (value.cmeType == 3 || this.weChatType === 2) {
          this.uploadImg(value);
        } else {
          let data = {
            name: value.title,
            contentId: value.cmeId,
            attachType: value.attachType,
            attachPath: value.cmeUrl || '',
          };
          this.saveData(data);
        }
      }
    },
    uploadImg(item) {
      let posterUrl = item.imgUrl;
      if (!item.imgUrl) {
        return Toast('图片地址不存在');
      }
      let form = new FormData();
      form.append('opId', localStorage.getItem('platform_opId') || '');
      form.append('weChatType', this.weChatType);
      form.append('posterUrl', posterUrl); //资源的url
      mine.uploadContentFile(form)
        .then((e) => {
          if (e.code == '0') {
            let data = {
              name: item.title,
              attachName: e.data.attachName,
              mediaId: e.data.mediaId,
              mediaTime: e.data.mediaTime,
              attachType: this.weChatType === 2 ? 3 : item.attachType,
              attachPath: e.data.attachPath || item.cmeUrl || '',
              contentId: item.cmeId,
            };
            this.saveData(data);
          }
        })
        .catch(() => {
          console.error('cme海报上传失败');
        });
    },
    saveData(data) {
      let annex = {
        attachName: data.name || '',
        attachType: data.attachType || '',
        attachPath: data.attachPath || '',
        contentId: data.contentId || '',
        mediaId: data.mediaId || '',
        mediaTime: data.mediaTime || '',
      };
      this.changeData.push(annex);
    },
    cancel() {
      this.$emit('update:contentShow', false);
    },
    submit() {
      this.$emit('addContent', this.changeData, 'cme');
    }
  },
};
</script>
<style lang="less" scoped>
.title{
  width: 100%;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .name {
    font-size: 17px;
    font-weight: 600;
    color: #303133;
  }
  .van-search{
    padding: 0px;
    .van-cell{
      padding: 0px;
      height: 28px;
      line-height: 28px;
    }
  }
}
.content-box{
  widows: 100%;
  height: calc(100% - 96px);
  overflow: auto;
}
.footer{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  background: #FFFFFF;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  .calc{
    width: 111px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #F0F4FE;
    border-radius: 6px 6px 6px 6px;
    font-size: 17px;
    font-weight: 600;
    color: #3A74F2;
  }
  .submit{
    width: 168px;
    height: 44px;
    line-height: 44px;
    text-align: center;
    background: #3A74F2;
    border-radius: 6px 6px 6px 6px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 17px;
  }
}
</style>
