/*
 * @Description: 
 * @Version: 2.0
 * @Autor: yuBiao
 * @Date: 2022-08-03 16:10:58
 * @LastEditors: yuBiao
 * @LastEditTime: 2022-10-13 10:58:00
 */
import request from '../../util/request';
import {CRM, BASIC} from '../apiConst';
export default {
  //获取此项目已选中的自定义字段集合
  getField(params) {
    return request.get({url: `${CRM}/crm/field/v1/cus-bind-fields`, params});
  },
  //通过项目ID查询来源树结构
  channelTree(params) {
    return request.get({url: `${BASIC}/api/know-way/list-by-tenant`, params});
  }
};
